import { NextPage } from 'next'

import getContentful from '@lib/contentful'
import processHomepage, {
  HomePageEntryI,
} from '@lib/contentful/entries/pages/home'

import Page from '@components/page'
import Unfurl from '@components/page/unfurl'
import HeroModule from '@components/undock/modules/hero'
import SideBySideModule from '@components/undock/modules/sideBySide'
import GridModule from '@components/undock/modules/grid'
import UpsellModule from '@components/undock/modules/upsell'
import {
  CenterModuleI,
  GridModuleI,
  HeroModuleI,
  SideBySideModuleI,
  TestimonialsModuleI,
  UpsellModuleI,
} from '@lib/contentful/entries'
import TestimonialsModule from '@components/undock/modules/testimonials'

export interface HomePagePropsI {
  // Modules
  hero?: HeroModuleI
  valueProp1?: SideBySideModuleI
  valueProp2?: SideBySideModuleI
  valueProp3?: SideBySideModuleI
  featuresGrid?: GridModuleI
  video?: CenterModuleI
  reserveHandle?: SideBySideModuleI
  testimonials?: TestimonialsModuleI
  calendarFeatures?: GridModuleI
  upsell?: UpsellModuleI

  // Metadata
  pageDescription: string
  pageImage: string
  pageKeywords: string[]
  pageTitle: string
}

const HomePage: NextPage<HomePagePropsI> = ({
  hero = null,
  valueProp1 = null,
  valueProp2 = null,
  valueProp3 = null,
  featuresGrid = null,
  video = null,
  reserveHandle = null,
  testimonials = null,
  calendarFeatures = null,
  upsell = null,
  pageDescription = 'Undock is the fastest way to find time to meet with anyone. Say hello to the super calendar with scheduling that works like autocomplete.',
  pageImage = '/img/undock-og-image-default.png',
  pageKeywords = ['scheduling', 'chrome extension', 'calendar', 'calendly'],
  pageTitle = 'The Most Powerful Calendar in the Galaxy',
}) => {
  return (
    <Page shouldScroll>
      <Unfurl
        description={pageDescription}
        image={pageImage}
        keywords={pageKeywords}
        title={pageTitle}
      />

      {hero && <HeroModule {...hero} />}
      {valueProp1 && <SideBySideModule {...valueProp1} />}
      {valueProp2 && <SideBySideModule {...valueProp2} />}
      {valueProp3 && <SideBySideModule {...valueProp3} />}
      {featuresGrid && <GridModule withoutTopPadding {...featuresGrid} />}
      {/* {video && <CenterModule {...video} />} */}
      {reserveHandle && <SideBySideModule {...reserveHandle} />}
      {/* {testimonials && <TestimonialsModule {...testimonials} />} */}
      {calendarFeatures && <GridModule withGradient {...calendarFeatures} />}
      {upsell && <UpsellModule {...upsell} />}
    </Page>
  )
}

export const getStaticProps = async ({ query }) => {
  const client = await getContentful()
  const entries = await client.getEntries<HomePageEntryI>({
    content_type: 'homePage',
    include: 10,
  })
  const entry = entries.items[0]
  const props = await processHomepage(entry)

  return {
    props: {
      ...props,
    },
  }
}

export default HomePage
